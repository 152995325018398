<!-- 我的收藏--收藏的专题 -->
<template>
  <div class="collect_data">
    <headers ref="headers" :headname="headName"></headers>
    <div class="main_box">
      <div v-for="(item,index ) in topicList" :key="index" class="topic_item">
        <a :href="item.url" target="_blank"><img class="thumb" :src="item.banner" alt=""></a>
        <div class="collect">
          {{ getNum(item.collect_num) }}
          <span class="text">收藏</span>
        </div>
      </div>
    </div>
    <!-- 有数据展示 -->
    <div v-show="count > 0" class="collect_list">

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>params.limit"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </div>

    <!-- 无数据显示 -->
    <noData v-show="count===0" ref="noData" :msg="msg"></noData>
  </div>
</template>

<script>
import { getVipCardCollection } from '@/api/center.js'
// import tool from '@/utils/tools.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },

  data() {
    return {
      headName: '收藏的专题',
      msg: '您还没有收藏的专题~',
      count: undefined,
      topicList: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },

  computed: {},

  created() {
  },

  mounted() {
    this.getVipCardCollectionFun()
  },

  methods: {
    // 我收藏的专题
    getVipCardCollectionFun() {
      getVipCardCollection(this.params).then((res) => {
        if (res.errNo == 0) {
          this.count = res.result.count
          this.topicList = res.result.list
        }
      })
    },
    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getVipCardCollectionFun()
    },
    getNum(num) {
      if (num < 1000) {
        return num
      } else if (num >= 1000 && num < 10000) {
        return parseInt((num / 1000 * 10)) / 10 + 'k'
      } else if (num >= 10000) {
        return parseInt((num / 10000 * 10)) / 10 + 'w'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collect_data {
    width: 965px;
    padding: 0 0 20px 0;
    min-height: 900px;
    position: relative;
    font-family: 'PingFangSC-Regular';
    .main_box{
      padding: 28px;
      display: flex;
      flex-wrap: wrap;
      .topic_item{
        width: 443px;
        height: 189px;
        margin-right: 23px;
        margin-bottom: 30px;
        position: relative;
        .thumb{
          width: 100%;
          height: 100%;
        }
        .collect{
          position:absolute;
          bottom:0;
          right: 0;
          background: rgba(51,51,51,0.90);
          border-radius: 6px 0px 0px 0px;
          color:#fff;
          padding: 2px 10px;
          font-size: 14px;
          .text{
            margin-left: 4px;
          }
        }
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
}

.collect_list {
    margin-left: 30px;
    padding: 0 30px 0 0;
}

.feny {
    display: inline-block;
    margin-top: 20px;
}
</style>
